<script setup>
import web3Login from "@/scripts/auth/web3Login.js";
import router from "@/router";
import ifAuth from "../../scripts/auth/redirectIfAuth";
import ErrorComp from "@/components/errormessage.vue";
import { ref } from "@vue/reactivity";
import getCookie from "@/scripts/utils/getCookie.js";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
const store = useStore();
const email = ref(null);
const password = ref(null);

onMounted(() => {
    ifAuth();
});

const submitLogin = () => {
    store.state.loader = true;
    window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.VUE_APP_reCAPTCHA_SITE_KEY, { action: "login" }).then(async (recaptchaToken) => {
            const response = await fetch("/api/login", {
                method: "POST",
                body: JSON.stringify({
                    recaptcha_token: recaptchaToken,
                    email: email.value,
                    password: password.value,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            });

            const data = await response.json();
            store.state.loader = false;
            if (data.ok) {
                store.state.authenticated = true;
                router.push({ name: "overview" });
            } else {
                store.state.errMsg = "Login Failed";
            }
        });
    });
};
</script>

<template>
    <div>
        <div class="buidl_wrap flex flex_col">
            <h2 class="ta_center">Rivet Login</h2>
            <form action="" class="rivet_login auth_form flex flex_col" @submit.prevent="submitLogin">
                <ErrorComp :errMsg="$store.state.errMsg" />
                <div class="input_data">
                    <div id="email rivet_login_email">
                        <input required v-model="email" type="email" autocomplete="email" placeholder="Email" />
                    </div>
                    <div id="password rivet_login_password">
                        <input required v-model="password" autocomplete="current-password" type="password" placeholder="Password" />
                    </div>
                    <div class="flex row_auth">
                        <div class="btn_web3_from_prompt">
                            <button type="button" @click.prevent="web3Login('Login')">
                                Login with Web3 <img width="15" src="@/assets/ethe_.svg" alt="" />
                            </button>
                        </div>
                        <p>
                            <span class="copy">
                                <router-link :to="{ name: 'forgot password' }"> Forgot Password? </router-link>
                            </span>
                        </p>
                    </div>
                </div>
                <div v-if="$store.state.loader" class="ajax_loader ta_center">
                    <img width="50" src="https://res.cloudinary.com/ferventdev/image/upload/v1655149185/rivet.cloud/1488_t1b6v8.gif" alt="" />
                </div>
                <div class="btn btn_color ta_center">
                    <button type="submit">Login</button>
                </div>
            </form>
            <div class="noAccount ta_center">
                <p>
                    Don’t have an account?
                    <router-link class="routing" :to="{ name: 'signup' }">Signup</router-link>
                </p>
            </div>
        </div>
        <div class="background_items">
            <div class="grade-w img">
                <img src="https://res.cloudinary.com/ferventdev/image/upload/v1655148858/rivet.cloud/grade_csysea.png" alt="" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.buidl_wrap {
    margin: 4rem auto;
    width: 40%;
    padding: 3rem 30px;
    gap: 2.8rem 0px;
    background: #ffffff;
    box-shadow: 0px 0px 60px rgba(53, 52, 52, 0.25);
    border-radius: 16px;
    @include media("<=desktop") {
        width: 50%;
    }
    @include media("<=tablet") {
        width: 70%;
        gap: 2rem 0px;
    }
    @include media("<=phone-tab") {
        width: 90%;
        padding: 1.5rem;
        gap: 2rem 0px;
    }
    .row_auth {
        flex-wrap: wrap;
        gap: 1rem 1rem;
    }
}
.grade-w {
    width: 30%;
    left: 12rem;
    z-index: -2;
    top: 15ch;
    animation: rotate 5s infinite ease-in-out;
}
</style>
